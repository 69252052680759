import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Parallax } from 'react-parallax'

import Fishin from '../img/fishin.jpg'
import FishingPole from '../img/fishing_pole.png'
import Anchor from '../img/anchor.png'
import Water from '../img/water.png'
import BuoyImg from '../img/buoy.png'
import Koi from '../img/koi.png'

import Layout from '../components/Layout/Layout'
import { Gallery } from '../components/Gallery';
import { Reviews } from '../components/Reviews';
import { HTMLContentConverter } from '../components/CMS/Content'

import One from "../img/gallery/one.jpeg"
import Two from "../img/gallery/two.jpeg"
import Three from "../img/gallery/three.jpeg"
import Four from "../img/gallery/four.jpeg"
import Five from "../img/gallery/five.jpeg"
import Six from "../img/gallery/six.jpeg"
import Seven from "../img/gallery/seven.jpeg"
const homePhotos = [
  {
    src: One,
    width: 4,
    height: 3,
    title: "That's a 15lb Steelhead hen!! At Baldwin Creek, it's an \"Able Nipper\" fish!"
  },
  {
    src: Two,
    width: 4,
    height: 3,
    title: "My best fish -- Coho caught on 5x tippet! Might have been a state record but I would have had to carry it 2 miles back to the truck and I was just too tired. Before you roast me for considering killing a fish -- this beast was on his way out and a state record would result in some free stuff, so I might have considered it. If it had not been a semiparity fish (programmed senescence) I would never consider killing a fish. Unless I was really, really hungry."
  },
  {
    src: Three,
    width: 4,
    height: 3
  },
  {
    src: Four,
    width: 3,
    height: 4
  },
  {
    src: Five,
    width: 5,
    height: 3,
    title: "That's my Dad!! I sure miss him. We didn't fish together very often, but when we did there was no more joy on a mans' face than when Pappy brought one in!"
  },
  {
    src: Six,
    width: 4,
    height: 3
  },
  {
    src: Seven,
    width: 5,
    height: 4,
    title: "Places like this allow you to completely forget the trials and tribulations of every day life....."
  }
];

export const IndexPageTemplate = ({ intro, read_more_text, tip, quote, pictures_title, reviews, heroes_text }) => {
  return (
    <div className="content content-body">
      <div className="textured-bg" style={{paddingTop:"0px",paddingBottom:"100px"}}>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",marginTop:"-3px"}}>
          <img src={BuoyImg} width="60px" alt="buoy" />
          <hr style={{marginTop:"-2px", marginBottom:"25px", maxWidth:"200px", borderWidth:"2px", borderColor:"#287cb0"}}/>
        </div>
        <div className="container content content-body" style={{marginTop:"25px",display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
          <HTMLContentConverter content={intro} />
          <Link style={{marginTop:"50px"}} to="/about">{read_more_text}</Link>
        </div>
      </div>
      <Parallax
        bgImage={Fishin}
        strength={200}
        bgImageStyle={{
          filter:"blur(1px)"
        }}
      >
      <div style={{ padding:"50px 50px 75px 50px", display:"flex", alignItems:"center", justifyContent:"center" }}>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <div style={{maxWidth:"1200px"}}>
            <h4 style={{color:"white", fontSize:"40px", marginBottom:"50px"}}>Pro Tips</h4>
            <div style={{ width:"100%", maxWidth:"750px", textAlign:""}}>
              <p className="site-banner-desc" style={{opacity:"0.9", color:"white", marginBottom:"50px", letterSpacing:"2px"}}>
              {tip}
              </p>
            </div>
             <Link style={{border:"1px solid white", color:"white", textDecoration:"none",borderRadius:"5px", padding:"5px 15px"}} to="/snippets">Get more tips</Link>
            </div>
          </div>
        </div>
      </Parallax>
      <div className="textured-bg">
        <div className="container content content-body" style={{marginTop:"20px",marginBottom:"30px",display:"flex", justifyContent:"center", alignItems:"center"}}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <p style={{maxWidth:"750px", display:"flex", flexDirection:"column", alignItems:"center", textAlign:"center"}}>
              <img src={FishingPole} width="85px" alt="fishing pole" style={{float:"left", marginTop:"15px", marginBottom:"25px"}}/>
              {quote?.line}
            </p>
          </div>
          <hr style={{marginTop:"0", marginBottom:"25px", width:"40px", borderWidth:"2px", borderColor:"#7b7b7b"}}/>
          <p>{quote?.author}</p>
        </div>
      </div>
      <div className="dark-block">
        <div style={{ padding:"50px 50px 75px 50px", display:"flex", alignItems:"center", justifyContent:"center" }}>
          <div style={{maxWidth:"1200px", width: "100%"}}>
            <Reviews reviews={reviews ? reviews : []}/>
          </div>
        </div>
      </div>
      <div className="textured-bg" >
        <div className="container content content-body" style={{marginTop:"20px",marginBottom:"30px",display:"flex", justifyContent:"center", alignItems:"center"}}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{maxWidth:"750px", display:"flex", flexDirection:"column", alignItems:"center"}}>
              <img src={Anchor} width="65px" alt="anchor pole" style={{float:"left", marginTop:"15px", marginBottom:"25px"}}/>
              <HTMLContentConverter content={pictures_title} />
            </div>
          </div>
          <hr style={{marginTop:"0", marginBottom:"25px", width:"40px", borderWidth:"2px", borderColor:"#7b7b7b"}}/>
        </div>
        <div className="container" style={{marginBottom: "100px"}}>
          <Gallery photos={homePhotos} />
        </div>
      </div>
      <Parallax
        bgImage={Water}
        strength={200}
        renderLayer={(percentage) => {
          const opacity = Math.min(percentage * 1, 0.35)
          const size = Math.max(50, Math.min(percentage*500, 225))
          return (
            <div>
              <div
                style={{
                  position: "absolute",
                  background: `rgba(255, 255, 255, ${opacity})`,
                  left: "50%",
                  top: "50%",
                  borderRadius: "50%",
                  transform: "translate(-50%,-50%)",
                  width: size,
                  height: size,
                }}
              />
            </div>
          )
        }}
      >
        <div style={{ height: 300 }}>
          <span style={{
            color: "white",
            padding: "10px 20px 20px 20px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            fontSize: "25px",
            letterSpacing:"3px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center"
          }} to="/giveback">
            <img height="50px" width="50px" src={Koi} alt="fish"/>
            <span style={{whiteSpace:"nowrap"}}>GIVE BACK</span>
          </span>
        </div>
      </Parallax>
      <div className="textured-bg" style={{paddingTop:"50px"}}>
        <div className="container content content-body" style={{display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
          <HTMLContentConverter content={heroes_text} />
        </div>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",marginTop:"-3px"}}>
          <hr style={{marginBottom:"-2px", marginTop:"25px", maxWidth:"200px", borderWidth:"2px", borderColor:"#287cb0"}}/>
          <img src={BuoyImg} width="60px" alt="buoy pole" />
        </div>
      </div>
    </div>
  );
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout isHome={true} title={frontmatter?.title} location={location}>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        intro
        read_more_text
        tip
        quote {
          line
          author
        }
        reviews {
          title
          location
          href
          review
        }
        pictures_title
        heroes_text
      }
    }
  }
`
