import React, { useState } from 'react'
import { HTMLContentConverter } from '../components/CMS/Content'

export const Reviews = ({reviews}) => {
  const maxReviews = reviews.length
  const [currentReview, setCurrentReview] = useState(0);
  const r = reviews[currentReview] || {}

  return (
    <>
      <div style={{display:"flex", justifyContent:"space-between", marginBottom:"50px"}}>
        <span style={{display:"flex", alignItems:"center"}}>
          <img alt="star" height="30px" width="30px" style={{marginRight:"10px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAER0lEQVRoge2aT2hcVRjFz41/AoltWhu1xapoUdsE3ISKldYqdqEUgxuXXSruXFXXKsVC3YlQUXBfumoX1aamhlii1moRm5Ro1VgLVhMEI4YomZ+LudPc3LnvvXtn3uQh9sCQmffuPd85893ve+/djHQd/3MAdwBvAm8Dd1atpy0ABjjDMj4HTNW6WgYwTDOeqVpXy7AZ8XHuP5kl4OmAmQaeqlpfMoDxHEMTVetLArAnx0wDe6rWGQ1gzBN/AvjAO/ZJ1TqjAOwOZGMn8Ejg+GNV6y0EcMoT/aFz7qR3bqRKrYUAdgSysKvg/M4qNeciUCcnA2NGvDEnqtBaCGAIqGVlxxn3aCBLD1ehORfAcU/kqZyxH3ljj62m1lwAG4BnY7LjzNnlja1Zjg3t6om6pwJulrRZ0qCkAUn32degpE2BKaPGmCcLOEclPRE49buk753XpKQLkqaNMfNFWq8ZArokbZH0gPO63/7drEjzFo8bY8byBgC7JX2cwImknyVNS/rW/m28Lhljai75ADAVKNZWcDxaYXPdtYopYJtLfLgNsgXga+AosB9Yk2BojZ1z1HIstKHjfUm60XL/URC7JmlGy6m+6Lz/aUW6E2Br4pBjsEvS3Vpe7lud9/dI6sqhW64v6p1qMuD6MrAd6G5FcJkAuq2WywGdk8Bt/oTbbdp9jAG3VOTD1ddD811Gw0yo00rAeuBsYNI4CbVRNoBemi/GAOebMhOYvJ7wXsAZYO0qeXD19AKnA3q+AvpjSdYBnwZIvgBu7bAHV0cfMBHQcY7Uu4ocsi+TyVqA/VI/K/VLLUh3/tptA2TXcvvLnuyCzO4u7cXL6rblNSZrajTD1LpSgujaMgtdD0eB3rLiNIJlXQdeLTHGawH+EaAnliPvVmIFjDF/SRqW5G8Sltkg/GKfkDRsY0ch2pAkGWMWJM16h6dSOApw0fs8a2NGI8mQxaD3+UILHFnwuQZK5G6GraMlb42X1r6Bfo97KbUZpGZomzfnF2PMb4kcmTDGzEq66hzqkvRgCkeqIX8JfJM4PwY+p7/Ec9GuocL6of4M8x7wLrA9IoZvqHN1BBzz1vjzOWO3Akdo3t4aAR7KmfeCN75ze3bAJS/YjsCYu4B3gH8CF0m32I8AWwLz/Z3V7zplxu9wNaDPOd8PHCRto+Nva36Tw7OWlVlN7nSxhoY8MTP2eB/wOjCfI3yc/H9RzluOPss5450f6oShfV6Q08DLwFyO0PPAXodjrz2WhTnL6T+y7OuEoYM5Qnz8SL24bwjwGOA5YDqB741OGPI7XAi/Aq8Qse0F3GRNX4ngLb/T0dzhXMxZI9G3+Q5vD/AScDWHv9xOB3TRfA8H8CdwgBIe8qg/3B2wnD6WKPvXJ9Q3JxpYBN4CNpYapB5no+VedOKdjZ0f7Zr6T8P2S1qUdNgY80MLeqMB3CvpRUndkg4ZY650Mt51rBb+BTShhG16731AAAAAAElFTkSuQmCC"></img>
          <h4 style={{color:"white", fontSize:"40px",margin:0}}>Guide Reviews</h4>
        </span>
        <div style={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems: "center"}}>
          <div style={{display:"flex", height:"40px"}}>
            <button aria-label="left-nav" onClick={() => {
              if (currentReview === 0) {
                setCurrentReview(maxReviews - 1)
              } else {
                setCurrentReview(currentReview-1)
              }
            }} className="site-nav-button" style={{backgroundColor:"transparent", padding:"5px 5px 5px 10px", marginRight:"5px"}}><span style={{padding:"5px"}} className="arrow left"></span></button>
            <button aria-label="right-nav" onClick={() => {setCurrentReview((currentReview+1) % maxReviews)}} className="site-nav-button" style={{backgroundColor:"transparent", padding:"5px 10px 5px 5px"}}><span style={{padding:"5px"}} className="arrow right"></span></button>
          </div>
        </div>
      </div>
      <div style={{color:"white", fontSize:"18px", width:"100%"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}} className={"reviews-review"}>
          <h4 style={{color: "white",marginBottom:"20px"}}><a href={r.href} style={{textDecoration:"underline"}} target="_blank" rel="noreferrer">{r.title}</a> {!r.location ? "" : <span>({r.location})</span>}</h4>
          <div style={{fontSize:"25px"}}>{currentReview+1}/{maxReviews}</div>
        </div>
        <p>
          <HTMLContentConverter content={r.review} />
        </p>
      </div>
    </>
  )
}